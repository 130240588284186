import { Injectable } from '@angular/core';

import {
  BlogCategoriesGQL,
  BlogCategory,
  BlogGQL,
  BlogsGQL,
} from '@alan-apps/data-access';
import { toNodes } from '@alan-apps/utils';
import {
  BaseHttpService,
  cache,
  ICache,
  useI18nRouter,
} from '@nghedgehog/angular-ui';

@Injectable({
  providedIn: 'root',
})
export class BlogService implements ICache {
  readonly storageKey = 'BlogService';
  private router = useI18nRouter();

  constructor(
    private _http: BaseHttpService,
    private blogGQL: BlogGQL,
    private blogsGQL: BlogsGQL,
    private getBlogCategoryPageGQL: BlogCategoriesGQL,
  ) {}

  search(query: any) {
    this.router.navigate(['/blog'], {
      queryParams: query,
    });
  }

  get(id: number) {
    return this._http.apollo(this.blogGQL.fetch({ id }));
  }

  list(option: PageOption, query = {}) {
    return this._http.apollo(
      this.blogsGQL.fetch({
        ...option,
        query: {
          ...query,
          type: '0,1',
        },
      }),
    );
  }

  @cache()
  category() {
    return this._http
      .apollo(
        this.getBlogCategoryPageGQL.fetch({
          skip: 0,
          take: 10000,
          orderBy: 'sort',
          reverse: true,
          query: {
            type: '0,1',
          },
        }),
      )
      .pipe(toNodes<{ nodes: BlogCategory[] }>());
  }
}
